@import '../sass/design/variables';
@import 'design/template/mixin_templates';

/*-----------------------------
    Keyframes
-------------------------------*/

@keyframes SubMenuAnimIn1 {
    0% {
        opacity: 0;
        transform: translateX(20%);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

/*-----------------------------
    Fin keyframes
-------------------------------*/

/*-----------------------------
    General (a trier)
-------------------------------*/

html, body {
    background-repeat: repeat;
    margin: 0;
    font-family: 'Source Sans Pro', helvetica, arial, sans-serif;
    font-size: 16px;
    line-height: 1.28;
}

@include titreBaseSize();

body > header {
    background-repeat: repeat;
    background-position: 0 0;
    height: 120px;
    position: relative;
}

.clearfix {
    &:before, &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

#wrapper {
    max-width: 1020px;
    margin: 0 auto;
}

.container {
    display: block;
    width: 910px;
    margin: 0 auto
}

.adr {
    border-radius: 50% 50% 50% 50%;
    font-size: 12px;
    height: 130px;
    margin-top: 20px;
    padding: 25px 30px 25px 25px;
    width: 130px;
}

.blackcaption .caption-contain {
    margin-top: 25px;
}

h2, .h2 {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin: 0;
    padding: 10px 0
}

.page h2, .page .h2 {
    margin: 0
}

.title_section {
    margin: 0 20px 20px
}

.title_section a {
    text-decoration: none
}

/*-----------------------------
    Fin general
-------------------------------*/

/*-----------------------------
    header
-------------------------------*/

#headercontent {
    position: relative;
    max-width: 1020px;
    width: 100%;
    height: 120px;
    margin: 0 auto;
}

.header-conteneur {
    position: relative;
    max-width: 1020px;
    width: 100%;
    margin: 0 auto;
}

.banniere.header_p {
    position: relative;
    z-index: 999;
}

/* Logo Slogan */

.logo {
    display: inline-block;
    position: absolute;
    left: 40px;
    top: 10px;
    img {
        max-height: 100px;
        max-width: 100%;
    }
}

.slogan {
    position: absolute;
    left: 27%;
    top: 5%
}

/*-----------------------------
    fin header
-------------------------------*/

/*-----------------------------
    Navigation
-------------------------------*/

.menu.navigation_p {
    width: 100%;
    margin: 0 auto;
}

//Menu principal

nav#nav-principal {
    width: 238px;
    position: absolute;
    border-radius: 10px 10px 10px 10px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 1px 0 3px #888888;
    margin-top: 40px;
    > ul {
        margin: -1px 0 0 0;
        > li {
            display: block;
            position: relative;
            ul {
                display: none;
                z-index: 1000;
                position: absolute;
                top: -1px;
                left: 100%;
                margin: 0;
                padding: 0;
                border-style: solid;
                border-width: 1px;
                opacity: 0;
                min-width: 200px;
            }
            li {
                position: relative;
                list-style: none;
                opacity: 1;
                transition-property: opacity;
                transition-duration: 0.3s;
                transition-timing-function: ease-in-out;
            }
            &:first-of-type {
                border-radius: 10px 10px 0 0;
            }
            &:last-of-type {
                border-radius: 0 0 10px 10px;
            }
            &:first-child {
                border-radius: 10px 10px 0 0;
                &:hover, &.actif {
                    border-radius: 10px 10px 0 0;
                }
            }
            &:last-child {
                border-bottom: none;
                border-radius: 0 0 10px 10px;
                &:hover, &.actif {
                    border-radius: 0 0 10px 10px;
                }
            }
        }
        &::before, ::after {
            display: block;
            width: 0;
            height: 0;
        }
    }
    ul {
        padding: 0 0;
        li {
            border-bottom-style: solid;
            border-bottom-width: 1px;
            list-style: none;
            transition-property: padding;
            transition-duration: 0.2s;
            transition-timing-function: ease-in-out;
            a {
                text-decoration: none;
                padding: 10px 15px 12px;
                display: block;
                &.actif + ul:after {
                    border: medium none;
                }
            }

            &:hover > ul {
                display: block;
                opacity: 1;
                animation: SubMenuAnimIn1 0.4s ease;
            }
        }
    }
}

.cmonsite-panier-2 #nav-principal > ul > li:last-child {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-radius: 0;
}

//Fil d'ariane

nav.arianne {
    margin: 0;
    clear: both;
    ul {
        margin: 0;
        padding: 7px 0 5px 7px;
        li {
            display: -moz-inline-stack;
            display: inline-block;
            zoom: 1;
            font-size: 12px;
            vertical-align: top;
            list-style: none;
            *display: inline;
            _height: 25px;
            margin: 0;
            &:not(:last-child):after {
                content: "\f054";
                font-family: FontAwesome, sans-serif;
                font-size: 10px;
                margin: 0 5px;
            }
            a.actif {
                text-decoration: none;
            }
        }
    }
}

/*-----------------------------
    Fin Navigation
-------------------------------*/

/*-----------------------------
    content
-------------------------------*/

.wrapper-content {
    max-width: 1020px;
    width: 100%;
    height: auto;
    margin: 0 auto;
    top: 160px;
    padding-left: 250px;
    box-sizing: border-box;
    iframe {
        max-width: 100%;
    }
}

.content {
    padding: 0 20px;
}

#main-conteneur .wrapper-content .wrapper .slide-text .content-slide-text p {
    width: 100%;
    bottom: 70px;
    left: auto;
    margin: auto;
    box-sizing: border-box;
}
#main-conteneur .wrapper-content .accueil_diaporama_template_6 .slide-text .content-slide-text > p{
    bottom:0;
}

#main-conteneur .wrapper-content #wrapper #bloc-fil-title {
    h2, .h2, h1, .h1 {
        display: none;
    }
}

#main-conteneur .wrapper-content #wrapper {
    padding-top: 15px;
    border-left: 1px solid #000;
    box-sizing: border-box;
}

.cmonsite-ficheproduit #main-conteneur .wrapper-content #wrapper {
    margin: 30px 0;
    padding-top: 0;
}

/*-----------------------------
    fin content
-------------------------------*/

/*-----------------------------
    slider
-------------------------------*/

.bx-wrapper {
    .bx-controls-direction a {
        position: absolute;
        width: 32px;
        height: 32px;
        text-indent: -9999px;
        z-index: 100;
        font-size: 47px;
        margin-top: -16px;
        top: 50%;
        outline: medium none;
        &.disabled {
            display: none;
        }
    }
    .bx-pager, .bx-controls-auto {
        position: relative;
        margin-bottom: 15px;
    }
}

.slide {
    padding: 0 0;
    > div {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }
}

.wrapper.accueil_diaporama_template {
    padding-left: 15px;
    margin-top: 15px;
    box-sizing: border-box;
    border-left: 1px solid #000;

    .bx-wrapper {
        margin-bottom: 0;

        .addcommentaires {
            top: 15px;
            right: 15px;
            bottom: auto;
            left: auto;
        }

        .slider-see-more {
            bottom: 7px;
            left: 0;
            right: 0;
            top: auto;
            width: auto;
            margin: 0 auto;
            max-width: 15%;
            text-align: center;
            border-radius: 0;
        }

    }

    &.accueil_diaporama_template_1 {
        a.bx-prev {
            left: 15px;
        }
        a.bx-next {
            right: 15px;
        }
    }

    &.accueil_diaporama_template_5 {
        .slide .slide-text .content-slide-text > p {
            bottom: 48px;
        }
        .bx-wrapper {
            .slider-see-more {
                max-width: 90%;
                box-sizing: border-box;
            }
        }
    }
}

.cmonsite-index-diaporama-2 #main-conteneur, .cmonsite-index-diaporama-4 #main-conteneur {
    #nav-principal {
        margin-top: 630px;
    }
    .accueil_diaporama_template {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 120px;
        margin: 0 auto;
        padding: 0;
        border: none;
        max-height: 600px;
        .bx-prev {
            left: 15px;
        }
        .bx-next {
            right: 15px;
        }
        .bx-pager {
            display: none;
        }
    }
    #wrapper {
        margin-top: 630px;
        padding: 0;
    }
}

/*-----------------------------
    fin slider
-------------------------------*/

/*-----------------------------
    Boutique et galerie
-------------------------------*/

.mask {
    transition: all 0.3s linear;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0) ";
    filter: alpha(opacity=0);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 20;
    margin: 0;

    a.zoom.produit_galerie_background_p {
        border-radius: 50%;
    }
    &:hover {
        width: 100%;
        margin: 0;
        text-align: center;
        opacity: 1;
    }
}

/*-----------------------------
    fin Boutique et galerie
-------------------------------*/

/*-----------------------------
    Boutique
-------------------------------*/

.produits-accueil {
    .produits {
        .hors-stock {
            width: 100%;
        }
    }
}

.productImageWrap {
    position: relative;
    width: 100%;
}

.list-produits {
    clear: both;
    overflow: hidden;
    _overflow: visible;
    _zoom: 1;
}

.produits {
    text-align: center;
    margin-bottom: 15px;
    position: relative;
    .produit_etiquette {
        width: 100%;
        z-index: 100;
        height: auto;
    }
    .remise {
        display: block;
        position: absolute;
        top: 55px;
        right: 1px;
        left: auto;
        width: 45px;
        height: 45px;
        padding: 13px 4px 10px 7px;
        border-radius: 50px 50px 50px 50px;
        font-size: 15px;
        font-weight: bold;
        white-space: nowrap;
        box-sizing: border-box;
        z-index: 5;
    }
    .productImageWrap {
        z-index: 1;
        a.zoom {
            border-radius: 50%;
            background: #000;

            &::before {
                //display: none;
            }
        }
    }
    .zoom::before {
        display: none;
    }
    .prix {
        text-align: center;
        width: 100%;
        margin: 10px 0;
        span {
            float: none;
        }
        .prixprod {
            font-size: 20px;
        }
        .oldprix {
            text-decoration: line-through;
            font-size: 13px;
            white-space: nowrap;
        }
    }
    .mask a {
        display: inline-block;
        float: none;
    }
    .zoom.produit_galerie_background_p.button.border_p {
        position: absolute;
        top: 0;
    }
    img {
        width: 100%;
        border-radius: 50%;
        margin: 0;
    }
    .nomprod {
        margin: 0;
        padding: 5px 0;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        text-align: center;
        font-size: 16px;
        .desc {
            padding: 0 0 5px;
            border-bottom-style: solid;
            border-bottom-width: 1px;
            font-size: 13px;
            margin: 0;
            &:empty {
                border-color: transparent;
            }
        }
        a {
            text-decoration: none;
        }
    }
    .productContentWrap {
        text-align: center;
    }
    .attr {
        text-align: center;
        float: none;
        width: auto;
        margin:10px;
        position: relative;
        height: 28px;
        select {
            right: auto;
            width: 100%;
            margin-bottom: 8px;
            padding: 4px;
            border-style: solid;
            border-width: 1px;
            box-sizing: border-box;
        }
    }
    .addbasket-product, .addbasket {
        float: none;
        display: inline-block;
        padding: 6px;
        margin: 10px auto;
        width: 100%;
        margin-right: 5%;
        text-align: center;
        cursor: pointer;
        line-height: 14px;
        box-sizing: border-box;
        .icon-cart {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 20px;
            width: 30px;
            height: 30px;
            transition-property: all;
            transition-duration: 0.3s;
            transition-timing-function: ease-in-out;
        }
    }
    .hors-stock {
        float: none;
        margin: 10px auto;
        width: 50%;
        height: 22px;
        margin-top: 0;
    }
}

.template_boutique {
    &.template_boutique_1 .produits {
        .desc {
            display: block;
            padding: 5px 0;
        }
    }
    &.template_boutique_3 .produits {
        .remise {
            right: auto;
            left: 24%;
            z-index: 5;
            span {
                font-size: 15px;
            }
        }
        .productContentWrap {
            .attr {
                width: 50%;
                margin: 0 auto;
                select {
                    float: none;
                }
            }
            .hors-stock .sub-hors-stock {
                width: 50%;
                margin: 0 auto;
            }
            .addbasket {
                position: relative;
                bottom: auto;
                left: auto;
                right: auto;
            }
        }
    }
    &.template_boutique_4 .produits {
        .remise {
            z-index: 5;
            span {
                font-size: 15px;
            }
        }
        .productImageWrap {
            position: relative;
        }
        .container-etiquette {
            position: absolute;
            top: 0;
            width: 100%;

            .produit_etiquette h3,
            .produit_etiquette .h3 {
                padding: 5px;
            }
        }
        .attr select {
            left: auto;
        }
    }
    &.template_boutique_5 .produits {
        .produit_etiquette {
            font-size: 14px;
            padding: 5px;
        }
        .remise {
            display: none;
        }
        .attr {
            float: left;
            display: block;
            width: 100%;
            text-align: center;
        }
        .hors-stock {
            width: 100%;
        }
        .prix {
            text-align: center;
        }
    }
}

.produit_etiquette h3, .produit_etiquette2 h3, .produit_etiquette3 h3,
.produit_etiquette .h3, .produit_etiquette2 .h3, .produit_etiquette3 .h3 {
    padding: 8px 0;
}

/*-----------------------------
    fin boutique
-------------------------------*/

/*-----------------------------
    fiche produit
-------------------------------*/

#fiche-produit {
    margin: 20px 0;
    #boutique {
        clear: both;
        overflow: hidden;
        _overflow: visible;
        _zoom: 1;
    }
    .container-etiquette {
        width: 100%;
        .produit_etiquette {
            width: 100%;
        }
    }
    .search-bar .search-bar-icon {
        width: 8%;
    }
    .remise-produit {
        top: auto;
        display: block;
        font-size: 12px;
        font-weight: bold;
        height: 60px;
        position: absolute;
        width: 60px;
        text-align: center;
        padding: 0;
        line-height: 60px;
        z-index: 101;
        bottom: 0;
        right: 0;
        border-radius: 50%;
    }
    .quantite {
        width: auto;
    }
    .wrap-description {
        position: relative;
        .h1 {
            margin-top: 0;
        }
        .brand {
            margin: 0;
            border: none !important;
        }
        .triangle-ficheprod {
            position: absolute;
            top: 100px;
            left: -85px;
            z-index: 9999;
        }
        .details-ficheprod {
            text-align: center;
        }
        .bloc-quantite {
            .quantite{
                border-style: inset;
                border-width: 2px;
                border-color: initial;
                border-radius: 0;
            }
            .bt-quantity{
                height: auto;
            }
        }
        .ajouter-panier {
            position: relative;
            .remise-produit {
                //display: none;
            }
            a.addbasket.button {
                display: inline-block;
                margin-top: 5px;
            }
        }
        .sociaux {
            padding: 9px 0 8px;
        }
    }
    .wrap-details {
        padding: 15px 0 30px;
        &.bloc-description-detaille {
            font-size: 12px;
        }
    }
    .wrap-images {
        .grande-image > a > div > img {
            border: medium none;
            border-radius: 50% 50% 50% 50%;
            box-shadow: 0 2px 3px #888888;
            box-sizing: border-box;
            max-height: 300px;
            max-width: 100%;
            padding: 8px;
        }
        div.remise-produit {
            display: block;
        }
        /*.zoomPad {
            padding: 5px;
            box-shadow: 0 2px 3px #888888;
            border-radius: 50%;
        }*/

        img.imgZoomPad {
            border-radius: 50%;
        }

        .zoomPad {
            position: static;
            display: inline-block;
            width: auto;
            padding: 5px;
            border-radius: 50%;
            box-sizing: border-box;
            box-shadow: 0 2px 3px #888888;
        }

        .zoomPup {
            margin-top: 5px;
        }

        .previous {
            text-align: center;
            font-size: 22px;
            width: 20px;

            &::before {
                content: "\f053";
                font-family: FontAwesome, sans-serif;
                font-size: 22px;
                display: inline-block;
            }

            .fa {
                display: none;
            }
        }

        .next {
            text-align: center;
            font-size: 22px;
            width: 20px;

            &::before {
                content: "\f054";
                font-family: FontAwesome, sans-serif;
                font-size: 22px;
                display: inline-block;
            }

            .fa {
                display: none;
            }
        }
    }
}

.template_fiche_produit_1 #fiche-produit {
    .ajouter-panier {
        .triangle-ficheprod {
            display: none;
        }
    }
}

.template_fiche_produit_2 #fiche-produit {
    .top_fiche .wrap-description {
        padding: 0;
        .container-etiquette {
            left: 0;
        }
    }
    .remise-produit {
        top: 0;
        bottom: auto;
        left: auto;
        right: 0;
    }
    .wrap-images .bx-viewport {
        overflow: visible;
        .zoom-img-position {
            position: relative;
            width: auto;
            /*img {
                padding: 5px;
                box-sizing: border-box;
                box-shadow: 0 2px 3px #888888;
            }*/
        }
    }
    .wrap-description {
        .ajouter-panier {
            a.addbasket {
                display: inline-block;
                margin-top: 5px;
            }
        }
    }
    .pull-right {
        float: none !important;
    }
    #addcomment {
        max-width: 100%;
    }
}

.template_fiche_produit_3 #fiche-produit {
    .wrap-images {
        .remise-produit {
            //display: none;
        }
        .bx-viewport .sliderImageFicheProd {
            max-height: none;
        }
        /*.bx-viewport .zoom-img-position {
            img {
                padding: 5px;
                box-sizing: border-box;
                box-shadow: 0 2px 3px #888888;
                max-height: 90%;
            }
        }*/
    }
    .wrap-description {
        .bloc-quantite {
            order: 2;
            margin-top: 8px;
            width: 100%;

        }

        .ajouter-panier {
            order: 3;
        }

        .declinaison {
            order: 4;
        }

        .prix {
            order: 1;
            width: 100%;
            height: 80px;
            margin: 15px 0;
        }
        .ajouter-panier {
            a.addbasket {
                display: inline-block;
                margin-top: 5px;

            }
        }
    }
    .side-tabs a.active, .side-tabs.etapes li:nth-child(3) span.active {
        border: none;
    }
}

/*-----------------------------
    fin fiche produit
-------------------------------*/

/*-----------------------------
    galerie
-------------------------------*/

.template_album .galerie {
    position: relative;
    box-sizing: border-box;
    background: #222;
    .addcommentaires {
        top: 20px;
        opacity: 0;
        z-index: 20;
        margin: 0 auto;
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        font-size: 15px;
        padding: 0;
        font-weight: bold;
        background: transparent;
        color: #fff;
        display: block;
        margin-top: -10px;
        transition: opacity .2s linear;
        text-decoration: underline;
        line-height: 35px;
        height: 35px;
        width: 35px;
    }
    &.sans-texte .addcommentaires {
        top: 50%;
        margin-top: -15px;
    }
    .center-img {
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }
    .commentP {
        top: 0;
        height: auto;
        width: 100%;
        opacity: 0;
        position: absolute;
        display: block;
        font-size: 0;
        &::before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
        p {
            display: inline-block;
            vertical-align: middle;
            font-size: 1rem;
            text-align: center;
            width: 100%;
        }
    }
    .mask {
        background: #222;
    }
    p {
        color: #fff;
    }
    .addcommentaires {
        color: #fff;
    }
    &:hover {
        &.sans-texte .addcommentaires {
            top: 50%;
            margin-top: -14px;
        }
        .addcommentaires {
            opacity: 1;
        }

        .commentP {
            opacity: 1;
        }
    }
}

.template_album {
    &.template_album_1 .list-photos .galerie {
        border-radius: 50%;
        .mask, .commentP {
            border-radius: 50%;
        }
        .commentP {
            position: absolute;
            top: 50%;
            height: auto;
            bottom: auto;
            transform: translateY(-50%);
        }
        .center-img {
            top: 1.5%;
            left: 1.5%;
            height: 97%;
            width: 97%;
            border-radius: 50%;
        }
    }
    &.template_album_2 .list-photos .galerie {
        .commentP {
            position: absolute;
            top: 50%;
            height: auto;
            bottom: auto;
            transform: translateY(-50%);
        }
    }
    &.template_album_3 .list-photos .galerie {
        .commentP {
            top: auto;
            bottom: 0;
        }
    }
    &.template_album_5 .list-photos .galerie {
        .addcommentaires, &.sans-texte .addcommentaires {
            left: auto;
            top: -25px;
            right: 15px;
            margin: 0;
            transition: top .2s linear;
            color: #fff;
        }
        &:hover {
            .addcommentaires, &.sans-texte .addcommentaires {
                top: 15px;
            }
            > a, > a::after {
                opacity: 1;
            }
        }
    }
    &.template_album_4 .list-photos .galerie,
    &.template_album_5 .list-photos .galerie {
        .commentP {
            display: block;
            opacity: 1;
            position: relative;
        }
        &.sans-texte .addcommentaires, .addcommentaires {
            top: 20px;
            bottom: auto;
            right: 15px;
            left: auto;
            margin-top: 0;
        }
    }
}


#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    > .button {
        flex: 0 1 auto;
        margin: 15px 0;
        display: inline-block;
    }

    > .button + .button {
        margin-left: 15px;
    }

}


.modal {
    z-index: 2041;
}

.modal-backdrop {
    z-index: 2040;
}


#contentgalerie {
    padding: 0 20px;
}

.list-photos {
    margin: 0 0 0 0;
    overflow: hidden;
    _overflow: visible;
    _zoom: 1;
}

/*-----------------------------
    fin galerie
-------------------------------*/

/*-----------------------------
    livre d'or
-------------------------------*/

.livre_dor {
    padding: 0 0 20px;
    .button {
        float: right;
        margin: 8px 8px 8px 20px;
    }
    .buttonLivre::after {
        content: '';
        display: block;
        clear: both;
    }
}

.message_lo {
    position: relative;
    margin: 30px;
    padding: 40px 20px 45px;
    border-radius: 5px;
    overflow: hidden;
    h4, .h4 {
        right: 14px;
        bottom: 26px;
        margin: 0;
        font-size: 10px;
        font-style: italic;
    }
    .web {
        position: absolute;
        right: 14px;
        bottom: -2px;
        font-size: 12px;
        font-style: italic;
    }
    .note {
        position: absolute;
        top: 0;
        right: 10px;
        margin: 0;
        font-size: 19px;
        font-weight: bold;
        text-align: right;
    }
    .message {
        position: relative;
        margin: 0 0 20px;
        min-height: 50px;
        font-size: 13px;
    }
}

.template_livreor {
    &.template_livreor_1 {
        .message_lo {
            margin: 20px 0 0;
        }
    }
    &.template_livreor_2 {
        .message_lo {
            .note {
                position: relative;
                float: none;
            }
        }
    }
    &.template_livreor_3 {
        .message_lo {
            time {
                float: none;
                display: block;
            }
        }
    }
}

.diaporama {
    text-align: center;
}

.smiley img {
    margin: 0;
}

/*-----------------------------
    fin livre d'or
-------------------------------*/

/*-----------------------------
    contact
-------------------------------*/

.form {
    margin: 15px 0;
    &::after {
        content: '';
        display: block;
        clear: both;
    }
}

fieldset {
    float: left;
    width: 210px;
    margin: 0;
    padding: 0 0 50px;
    border: medium none;
    p {
        margin: 0 0 16px 0;
    }
    &.last {
        width: 525px;
    }
}

label {
    display: block;
}

input {
    width: 176px;
}

textarea {
    width: 98%;
    height: 182px;
}

input[type=text],
textarea {
    margin-top: 1px;
    padding: 2px;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px 3px 3px 3px;
}

.barre_outils {
    display: inline-block;
    vertical-align: top;
}

.select_style {
    display: inline-block;
    width: 92px;
    padding: 4px 0 0;
    vertical-align: top;
}

.white {
    margin: 32px 0 0;
}

.bloc-adresse {
    overflow: hidden;
}

.map, .adresse {
    float: left;
}

.adresse, .form-contact, .map {
    margin: 0 0 0 20px;
}

.adresse {
    height: 190px;
    right: -9px;
    position: absolute;
    bottom: -60px;
    width: 190px;
    z-index: 5000;
    border-radius: 50% 50% 50% 50%;
}

.bloc-adresse {
    position: relative;
}

.template_contact {
    &.template_contact_2 {
        input[type="text"] {
            border-width: 0;
        }
    }
    &.template_contact_3 {
        input {
            padding: 10px 10px 10px 40px;
            &:focus {
                padding-left: 35px;
            }
        }
        input[type="file"] {
            padding: 0;
            &:focus {
                padding: 0;
            }
        }
        input.button {
            padding: 10px;
        }
    }
    &.template_contact_4 {
        input {
            padding: 10px 10px 10px 15px;
        }
        input.button {
            padding: 10px;
        }
    }
}

/*-----------------------------
    fin contact
-------------------------------*/

/*-----------------------------
    footer
-------------------------------*/

.footer_p {
    //background-color: #222;

    .links_p {

        a.link {
            //color: #fff;
        }
    }

}

footer {
    padding: 10px;
}

#pub {
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
        display: block;
        margin: 15px auto;
    }
}

.mention {
    display: block;
    margin: 5px 0 0;
    padding: 10px 0;
    text-align: center;
}

.music {
    text-align: center;
}

/*-----------------------------
    fin footer
-------------------------------*/

.button,
.produits .addbasket,
.addmsglo {
    padding: 8px 16px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 11px;
    font-weight: bold;
    cursor: pointer;
}

.button {
    .fa {
        margin-right: 10px;
    }
}

.produits .addbasket {
    float: left;
    position: relative;
}

.contact_text {
    margin: 0 20px;
}

/* Template 2 panier */
body.cmonsite-panier-2 #nav-principal {
    border-radius: 10px 10px 0 0;
    > ul {
        float: none;
        max-width: 100%;
        + .template-panier.template-panier-2 {
            margin-top: -1px;
            min-width: 100%;
            float: none;
            border-left: none;
            border-right: none;
            #panier #header-panier {
                font-weight: normal;
            }
            #panier #paniercontent {
                top: 0;
                bottom: auto;
                left: 100%;
                right: auto;
                font-weight: normal;
            }
        }
    }
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {
    .side-tabs.etapes,
    div#resume .block-recap-panier,
    div#resume div.next-step,
    div#information #connexion-inscription,
    div#information div.next-step,
    #infoscommande,
    div#finish .block-info-payment,
    div#finish div.next-step,
    div#resume h4,
    div#finish h4,
    .message-commande {
        width: 100%;
    }

    h4 {
        margin: 0;
    }

    #connexion-inscription > .col.s6 {
        padding: 0;
        width: 100%;
        margin: 15px 0;
    }

    div#information .list_address {
        margin: 0 0 20px;
    }
}

.cmonsite-fluxpanier-2 #main-conteneur.page-panier_commande {
    #wrapper .content {
        padding-left: 0;
        padding-right: 0;
    }
}

body .template_flux_panier_2 {
    .block-resume, .block-information, .recapitulatif.recap-l, .recapitulatif.recap-r {
        width: 100%;
    }
    .form-order::before, .block-finish::before {
        display: none;
    }
    #resume, .recapitulatif.recap-l {
        background: rgba(0, 0, 0, 0.02);
    }
}

@media screen and (max-width: 768px) {

    .template-panier-3 {
        right: auto;
        left: 0;
        float: left;
    }

    #main-conteneur .wrapper-content .wrapper .slide-text .content-slide-text p {
        display: none;
    }
    .accueil_diaporama_template_5 .slide .slide-text .content-slide-text > p {
        display: block;
    }

    .cmonsite-index-diaporama-2 #main-conteneur, .cmonsite-index-diaporama-4 #main-conteneur {
        .accueil_diaporama_template {
            position: relative;
            top: auto;
        }
        #wrapper {
            margin-top: 10px;
        }
    }

    #main-conteneur .wrapper-content {
        padding: 15px;

        .wrapper.accueil_diaporama_template, #wrapper {
            border: none;
            padding: 15px;
            margin-top: 15px;
            position: relative;
            top: 0;
            height: auto;

            .bx-wrapper .bx-pager {
                position: relative;
                bottom: 0;
            }

            .produits-accueil.template_boutique_accueil {
                margin: 0;
                padding: 0;
            }

            .content_p section {
                padding: 0;
            }

        }

    }
}

@media screen and (max-width: 768px) {
    body {
        padding-left: 20px;
        padding-right: 20px
    }
    .navbar-fixed-top, .navbar-fixed-bottom, .navbar-static-top {
        margin-left: -20px;
        margin-right: -20px
    }
    .container-fluid {
        padding: 0
    }
    .dl-horizontal dt {
        float: none;
        clear: none;
        width: auto;
        text-align: left
    }
    .dl-horizontal dd {
        margin-left: 0
    }
    .container {
        width: auto
    }
    .row, .thumbnails {
        margin-left: 0
    }
    .thumbnails > li {
        float: none;
        margin-left: 0
    }
    .modal {
        position: fixed;
        top: 20px;
        left: 20px;
        right: 20px;
        width: auto;
        margin: 0
    }
    .modal.fade {
        top: -100px
    }
    .modal.fade.in {
        top: 20px
    }

    /* Tableaux responsive */
    @import 'design/helper/responsive/mobile/mobile';

}

@media (max-width: 480px) {
    .nav-collapse {
        transform: translate3d(0, 0, 0)
    }
    .page-header h1 small,
    .page-header .h1 small {
        display: block;
        line-height: 20px
    }
    input[type="checkbox"], input[type="radio"] {
        border: 1px solid #ccc
    }
    .form-horizontal .control-label {
        float: none;
        width: auto;
        padding-top: 0;
        text-align: left
    }
    .form-horizontal .controls {
        margin-left: 0
    }
    .form-horizontal .control-list {
        padding-top: 0
    }
    .form-horizontal .form-actions {
        padding-left: 10px;
        padding-right: 10px
    }
    .media .pull-left, .media .pull-right {
        float: none;
        display: block;
        margin-bottom: 10px
    }
    .media-object {
        margin-right: 0;
        margin-left: 0
    }
    .modal {
        top: 10px;
        left: 10px;
        right: 10px
    }
    .modal-header .close {
        padding: 10px;
        margin: -10px
    }
    .carousel-caption {
        position: static
    }
}

@media (min-width: 1200px) {
    .thumbnails {
        margin-left: -30px
    }
    .thumbnails > li {
        margin-left: 30px
    }
}

pre,
blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
}

thead {
    display: table-header-group;
}

tr,
img {
    page-break-inside: avoid;
    max-width: 100%;
    height: auto;
}

.livre_dor .row, #fiche-produit .row {
    margin-left: 0;
}

.steps-panier {
    padding-left: 15px;
}

/*-----------------------------
    MEDIA QUERIES
-------------------------------*/
@media screen and (max-width: 1280px) {
    #wrapper {
        padding: 0 20px 30px;
    }
    .content img {
        max-width: 100%;
        height: auto;
    }
    .content table, #pub table {
        max-width: 100%;
    }
    .content table img, #pub table img {
        max-width: 100%;
    }
}

@media (max-width: 1024px) {
    .cmonsite-panier-3 #blocContent .template-panier.template-panier-3 {
        margin-bottom: 0;
    }
    .cmonsite-index-diaporama-2 #main-conteneur, .cmonsite-index-diaporama-4 #main-conteneur {
        #nav-principal, #wrapper {
            margin-top: 430px;
        }
    }
    #headercontent {
        width: 100%;
        &:after {
            content: '';
            display: block;
            width: 100%;
            clear: both;
        }
    }

    .content img, .content table img, #pub table img {
        height: auto;
    }
    .content table {
        width: 100% !important;
    }
}

@media screen and (max-width: 980px) {
    .slogan_p {
        font-size: 1.8em !important;
    }

    #wrapper {
        padding: 0 15px 30px;
    }
    .mask > .commentaire {
        margin: 18% 10px 0;
    }
    .mask > h3,
    .mask > .h3 {
        display: none;
    }
    .form input {
        width: 90%;
    }
    .form input[type=submit] {
        width: 176px;
    }
    .ui-datepicker {
        width: 90% !important;
        font-size: .8em;
        left: 4% !important;
    }
    .ui-datepicker td {
        display: table-cell;
        width: auto;
    }
}

@media screen and (max-width: 768px) {
    body {
        padding: 0;
    }
    nav#nav-principal {
        margin-top: 20px;
        text-align: center;
    }
    nav#nav-principal > ul {
        margin: 0;
    }
    nav#nav-principal > ul > li {
        display: inline-block;
    }
    nav#nav-principal > ul > li:first-child, nav#nav-principal > ul > li:first-child:hover, nav#nav-principal > ul > li:first-child.actif {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    nav#nav-principal > ul > li ul {
        top: 100%;
        left: 0;
        text-align: left;
    }
    #content_full #fiche-produit .wrap-details {
        padding: 15px 0;
    }
    .mask > .commentaire {
        margin: 9% 10px 0;
    }
    .mask > h3,
    .mask > .h3 {
        display: block;
    }
    .message_lo {
        margin: 30px 15px;
    }
    #block-flottant-search {
        display: none;
    }
    .logo {
        max-width: 100%;
        top: 0 !important;
        display: block !important;
        position: static !important;
        padding: 10px 0 !important;
        text-align: center;
        img {
            max-height: 50px !important;
            width: auto !important;
            height: auto !important;
        }
    }
    .logo img {
        max-width: 100%;
        height: auto !important;
    }
    .slogan_p {
        font-size: 1.5em !important;
        width: 100% !important;
        left: 0 !important;
        right: 0 !important;
        top: 0 !important;
        position: static !important;
        margin: 0 auto !important;
        padding: 15px 0 !important;
        text-align: center !important;
        height: auto !important;
    }

    .template_boutique_accueil .produits .remise {
        padding: 7px 4px 10px 2px;
    }

}

@media screen and (max-width: 680px) {
    .produits .produit_etiquette {
        height: 35px;
    }
    .produit_etiquette h3,
    .produit_etiquette .h3 {
        font-size: 1.1em;
        padding: 6px 0;
    }
    .mask > h3,
    .mask > .h3 {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    #fiche-produit #boutique {
        margin-top: 20px;
    }
    #fiche-produit form .input-form-moyen {
        width: 100%;
    }
    #fiche-produit form .input-form-petit {
        width: 30%;
    }
    .galerie {
        width: 48%;
        margin: 0 0 20px 2%;
        padding-top: 48%;
    }
    .mask > .commentaire {
        margin: 16% 10px 0;
    }
    .mask > h3,
    .mask > .h3 {
        display: block;
    }
    .addmsglo {
        margin-left: 15px;
    }
    form .row .col.s3 {
        width: 25%;
    }
}

@media screen and (min-width: 769px) {
    body.cmonsite-panier-2 .template-panier.template-panier-2 {
        position: static !important;
        top: auto !important;
        left: auto !important;
        right: auto !important;
        bottom: auto !important;
        #panier #header-panier {
            width: 100% !important;
            height: auto !important;
            position: static !important;
            text-align: left !important;
            font-size: 1rem !important;
            text-indent: 0 !important;
            .fa-shopping-cart {
                text-indent: 0 !important;
                font-size: 16px;
                margin-right: 10px;
            }
            .count-live {
                text-indent: 0 !important;
                left: 20px;
                top: 20px !important;
            }
        }
        #paniercontent {
            left: 100% !important;
            right: auto !important;
            min-width: 500px;
        }
    }
}

@media screen and (max-width: 768px) {
    body > header {
        height: auto;
    }

    #headercontent {
        height: auto !important;
    }

    .galerie {
        width: 100%;
        margin: 0 0 20px 0;
        padding-top: 100%;
    }
    /* Nav mobile */
    .sidebar nav {
        text-align: left;
        border: 1px solid #ccc;
        margin: 10px auto;
        padding: 0;
        position: relative;
        width: 90%;
        border-radius: 9px;
    }
    nav#nav-principal a {
        padding: 7px 24px;
    }
    .menu_burger {
        cursor: pointer;
        background-color: #fff;
        border-radius: 9px;
        display: block;
    }
    .menu_burger::before {
        font-family: FontAwesome, sans-serif;
        content: "\f0c9";
        margin-right: 5px;
    }
    .sidebar nav > ul {
        display: none;
        border-radius: 9px;
        overflow: hidden;
        box-shadow: 0 4px 2px rgba(11, 25, 28, 0.3);
        filter: progid:DXImageTransform.Microsoft.Shadow(color=#656565, Direction=174, Strength=10);
        margin: 1px 0 0 0;
    }
    .sidebar .nav_ouverte > ul {
        display: block;
        position: absolute;
        width: 100%;
        z-index: 1000;
    }
    .sidebar nav > ul > li {
        float: none;
        margin-right: 0;
        display: block;
        border-radius: 0;
    }
    nav > ul > li:hover {
        animation: none !important;
    }
    .sidebar nav > ul > li:first-child {
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
    }
    .sidebar nav > ul > li:last-child {
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
    }
    nav#nav-principal .navigation_button_p {
        margin-bottom: 0;
    }
    .navigation_button_p::before, .navigation_button_p::after {
        border: none;
    }

    nav#nav-principal ul li a {
        padding: 10px 15px 12px;
    }

    nav#nav-principal ul li:hover > ul {
        animation: none;
    }
    nav#nav-principal li {
        width: 100% !important;
        a {
            text-align: center !important;
        }
    }
    .dropDown {
        position: absolute;
        right: 2%;
        top: 4px;
        font-weight: bold;
        display: block;
        height: 28px;
        width: 8%;
        cursor: pointer;
        text-align: center;
        font-size: 1.2rem;
        padding-top: 5px;
    }
    nav#nav-principal > ul > li ul {
        display: block;
        position: relative;
        text-align: left;
        border: none;
        border-radius: 0;
        opacity: 1;
    }
    nav#nav-principal li ul li a {
        padding: 5px 10px 10px 30px;;
    }
    nav#nav-principal li li li a {
        padding: 5px 10px 10px 40px;;
    }
    nav#nav-principal li ul li {
        border-bottom: none;
    }
    /* FIN Nav mobile */

    .page table td {
        width: 100% !important;
        display: block;
    }
    .produits .produit_etiquette {
        height: 32px;
    }
    .produit_etiquette h3,
    .produit_etiquette .h3 {
        font-size: 1em;
    }
    .produits .remise {
        width: 33px;
        height: 33px;
    }
    .addbasket .icon-cart {
        display: none;
    }
    #content_full #fiche-produit .wrap-images, #content_full #fiche-produit .wrap-description {
        width: 100%;
        float: none;
    }
    #fiche-produit .wrap-images {
        margin: 0 auto 20px;
    }
    #fiche-produit .wrap-images .grande-image {
        max-width: 280px;
    }
    #fiche-produit .wrap-images .grande-image img {
        opacity: 1 !important;
    }
    #fiche-produit .zoomPad {
        cursor: default;
    }
    #fiche-produit .zoomPup, #fiche-produit .zoomWindow {
        display: none !important;
    }
    #fiche-produit #formulaire {
        margin-left: -15px;
        margin-right: -15px;
    }
    #fiche-produit .search-bar .search-bar-icon {
        width: 12%;
    }
    .mask > h3,
    .mask > .h3 {
        display: none;
    }
    #infoscommande fieldset {
        padding-left: 30px;
    }

    #main-conteneur #wrapper .template_boutique_2 .produits span.remise {
        padding: 6px 4px 10px 3px;
    }

    #main-conteneur .wrapper-content #wrapper .template_boutique.template_boutique_3 .produits span.remise, #main-conteneur .wrapper-content #wrapper .template_boutique.template_boutique_4 .produits span.remise {
        padding: 6px 4px 10px 3px;

        span {
            font-size: 13px;
        }

    }

    #main-conteneur #wrapper .template_album_2 .list-photos .galerie:hover .bloc-titre-galerie p {
        display: none;
    }
}

@media screen and (max-width: 400px) {
    #minisite-sidebar {
        display: none;
    }
    .slogan_p {
        font-size: 1.4em !important;
    }
    .sidebar nav {
        width: 100%;
    }
    #wrapper {
        padding: 0 10px 30px;
    }
    .content {
        padding-top: 25px;
    }
    .form input {
        width: 100%;
    }
    textarea {
        width: 100% !important;
    }
    .produits .remise {
        box-sizing: border-box;
        font-size: 18px;
        width: 52px;
        height: 52px;
        padding: 14px 10px 9px 4px;
    }
    .produits .desc {
        display: none;
    }
    .produits .addbasket {
        padding: 7px 10px;
    }
    #contentgalerie {
        padding: 0 10px;
    }
    body #panier {
        width: 80%;
        right: 7%;
    }
    .content #totalbloc {
        box-sizing: border-box;
        width: 100%;
    }
}

@media screen and (max-width: 360px) {
    .slogan_p {
        font-size: 1.3em !important;
    }
    .title_section {
        margin: 0 10px 15px;
    }
    nav.arianne {
        margin: 0 10px 25px;
    }
    .button, .produits .addbasket, .addmsglo {
        padding: 8px 14px;
    }
    .produit_etiquette h3,
    .produit_etiquette .h3 {
        font-size: .9em;
    }
    .produits .remise {
        top: 75px;
        font-size: 17px;
        width: 50px;
        height: 50px;
    }
    .produits .prixprod {
        font-size: 18px;
    }
    #fiche-produit .container-comment > div {
        padding: 12px !important;
    }
    #fiche-produit .search-bar .search-bar-icon {
        width: 15%;
    }
    .text-livre {
        margin: 0 10px 20px;
    }
    .addmsglo {
        margin-left: 5px;
    }
    .message_lo {
        margin: 30px 5px;
    }
    .formulaires_perso input[type=text].hasDatepicker {
        margin-bottom: 5px;
    }
    .ui-datepicker {
        width: 98% !important;
        font-size: .75em;
        left: 0 !important;
    }
    #infoscommande fieldset {
        padding-left: 20px;
    }
}

@media screen and (max-width: 320px) {
    form .row .col.s3 {
        width: 50%;
    }
    .produits .produit_etiquette {
        height: 28px;
    }
    .produit_etiquette h3,
    .produit_etiquette .h3 {
        font-size: .8em;
    }
    .produits .remise {
        font-size: 16px;
        width: 48px;
        height: 48px;
        top: 70px;
    }
    .produits .addbasket {
        padding: 6px 10px;
    }
}

@media screen and (max-width: 768px) {

    /* Template Responsive Panier */
    .cmonsite-panier-2 {
        .template-panier.template-panier-2 {
            position: absolute !important;
            width: 90% !important;
            margin: 0 auto !important;
            left: 5% !important;
            right: 5% !important;
            top: 75px;
            #header-panier {
                top: -65px;
                height: 62px !important;
                width: 62px !important;
                line-height: 42px !important;
                right: 44px;
                border-radius: 10px;
                .count-live {
                    left: 35px !important;
                    top: 15px !important;
                }
            }
            #paniercontent {
                top: 0 !important;
                bottom: auto !important;
                left: 0 !important;
                right: 0 !important;
                max-width: 100% !important;
                width: 100% !important;
            }
        }
    }

    .cmonsite-index-diaporama-2 #main-conteneur, .cmonsite-index-diaporama-4 #main-conteneur {
        #nav-principal {
            margin-top: 0 !important;
        }
    }

    .menu.navigation_p {
        width: 100%;

        #nav-principal {
            width: 100%;
            padding: 0;
            position: static;
            border-radius: 0;
            border: none;

            > ul {
                top: 110px;
                width: 100%;
                position: absolute;
                display: none;

                li {
                    height: auto;

                    &.hasDropdown > a::after {
                        display: none;
                    }

                    &:last-child {
                        border-radius: 0;
                    }

                }

                ul {
                    position: relative;
                    top: 0;
                    left: 0 !important;
                    max-width: 100%;
                    transform-origin: left center;
                    transform: scaleX(1);
                    transition-property: none;
                    margin-top: 0;
                    opacity: 1;

                    li {
                        border-right-width: 0;
                        border-left-width: 0;
                        &:last-child {
                            border-bottom-width: 0;
                        }
                    }
                }
            }
            li {
                width: 100%;
                display: block;
                &.sousmenu_ouvert > a::after {
                    left: 62px !important;
                    top: 98%;
                    margin-left: -36px;
                    transform: rotate(90deg);
                    opacity: 1 !important;
                }
            }
        }
    }

    .menu.navigation_p #nav-principal {

        a.menu_burger {
            text-align: center;
            float: right;
            position: absolute;
            width: 62px;
            height: 62px;
            line-height: 62px;
            right: 10px;
            top: 10px;
            font-size: 25px;
            display: inline-block;
            padding: 0;
            z-index: 9999;
            &::before {
                content: '\f0c9';
                display: inline-block;
                font-family: 'FontAwesome', sans-serif;
            }
        }

        ul {
            position: relative;
            top: 0;
        }
    }

    .dropDown {
        position: absolute;
        right: 0;
        top: 0;
        font-weight: bold;
        display: block;
        height: auto;
        width: 60px;
        cursor: pointer;
        text-align: center;
        font-size: 1.2rem;
        padding-top: 0;
        line-height: 43px;
        &::before {
            font-family: FontAwesome, sans-serif;
            content: "\f0d7";
        }
    }
    li.sousmenu_ouvert > .dropDown::before {
        content: '\f0d8';
    }

    .link_return_cmonsite, .link_return_cmonsite:visited {
        right: 15px;
    }

}

@media (min-width: 769px) {
    .menu_burger {
        display: none;
    }
}


.template_fiche_produit #fiche-produit .bloc-quantite{
    margin-bottom: 14px;
}